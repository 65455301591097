import React from 'react';
import web2 from '../Image/web1.png';
import web from '../Image/web.jpg';
import Testenomail from '../HomePage/Testenomail';

function WebDevelop() {
    return (
        <div data-aos="fade-up" data-aos-duration="2000" className="lg:px-20 px-5 mx-auto py-8 mt-12">
            <div className='text-center'>
                <h5 data-aos="fade-up"
                    data-aos-duration="2000" className='text-blue-500 text-aqua font-bold'></h5>
                <h2 data-aos="fade-up"
                    data-aos-duration="2000" className="text-3xl lg:text-4xl font-bold text-gray-900 dark:text-white mb-4">
                    Website Development
                </h2>
            </div>
            <div className="lg:flex lg:justify-between mt-20">

                <div className="lg:w-1/2 lg:mr-8 mb-8 lg:mb-0">
                    <h2 className="text-3xl lg:text-4xl font-bold text-gray-900 dark:text-white mb-4">
                        Creative Solutions for Your Business
                    </h2>
                    <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                        Web development is the process of creating websites and web applications. It involves various technologies, tools, and languages to build and maintain websites that are functional, user-friendly, and visually appealing. Here's a brief overview of key concepts in web development:
                    </p>
                    <p className="text-gray-700 dark:text-gray-300 leading-relaxed mt-7">
                        <h2 className='text-[25px]'>Frontend Development:</h2>

                        <li>Frontend development focuses on the client-side of web development.</li>
                        <li>Technologies like HTML, CSS, and JavaScript are used to create the structure, style, and interactivity of web pages.</li>
                        <li>Frameworks and libraries like React, Angular, and Vue.js help streamline the development process and enhance the user experience.</li>
                    </p>
                </div>
                <div className="lg:w-1/2">
                    <img
                        src={web2}
                        alt="Company Image"
                        className="w-full lg:h-full rounded-md"
                        style={{ maxHeight: '400px' }}
                    />
                </div>
            </div>
            <div className="mt-[120px]">
                <div className="lg:flex lg:justify-between">
                    <div className="lg:w-1/2 lg:mr-8 mb-8 lg:mb-0">
                        <img
                            src={web}
                            alt="Company Image"
                            className="w-full lg:h-full rounded-md"
                            style={{ maxHeight: '400px' }}
                        />
                    </div>

                    <div className="lg:w-1/2">

                        <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                            <h2 className='text-[25px]'>Backend Development:</h2>

                            <li>Backend development focuses on the server-side of web development.</li>
                            <li>It involves writing code that runs on the server and interacts with databases and other external services.</li>
                            <li>Common backend languages include Python, Ruby, PHP, and Node.js, with frameworks like Django, Ruby on Rails, and Express.js.</li>

                        </p>
                        <p className="text-gray-700 dark:text-gray-300 leading-relaxed mt-7">
                            <h2 className='text-[25px]'>Full Stack Development:</h2>

                            <li>Full stack developers work on both the frontend and backend of web applications.</li>
                            <li>They are proficient in a wide range of technologies and can handle all aspects of web development.</li>
                        </p>
                    </div>
                </div>
                <Testenomail />
            </div>
        </div>
    )
}

export default WebDevelop