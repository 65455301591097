import React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Topnav() {
    return (
        <div id="tophead" className="p-3  items-center bg-aqua px-20 hidden md:flex">
            <div className="container mx-auto">
                <div className="flex items-center justify-between">
                    <div className="topbar-left">
                        <div className="header-email header-item flex items-center">
                            <i className="far fa-envelope mr-2" />
                            <a href="mailto:info@devstree.com">info@devilinfotech.com</a>
                        </div>
                    </div>
                    <div className="topbar-right">
                        <ul>
                            <li className="topbar-social">
                                <div className="social-icon flex">
                                    <a target="_blank" href="facebook.com" className="mr-2 transition duration-300 ease-in-out transform hover:scale-110">
                                        <FacebookIcon />
                                    </a>
                                    <a target="_blank" href="https://twitter.com/devilinfotech?t=lhbvDCPaid3k0l7BLj9g2w&s=08" className="mr-2 transition duration-300 ease-in-out transform hover:scale-110">
                                        <TwitterIcon />
                                    </a>
                                    <a target="_blank" href="https://www.instagram.com/devilinfotech/" className="mr-2 transition duration-300 ease-in-out transform hover:scale-110">
                                        <InstagramIcon />
                                    </a>
                                    <a target="_blank" href="https://www.linkedin.com/in/devil-infotech-59162a305/" className="mr-2 transition duration-300 ease-in-out transform hover:scale-110">
                                        <LinkedInIcon />
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Topnav;
