import React from 'react';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { posts } from '../../constants/blogdata';

const BlogData = () => {

    return (
        <div data-aos="fade-up"
            data-aos-duration="2000" className='mt-20'>
            <p data-aos="fade-up"
                data-aos-duration="2000" className='text-blue-500 text-center text-aqua' ><h5><b>Latest News & Blogs</b></h5></p>
            <h2 data-aos="fade-up"
                data-aos-duration="2000" className="mb-3 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">What's New in the Business Area to Know</h2>
            <div data-aos="fade-up"
                data-aos-duration="2000" className=" flex flex-col lg:flex-row justify-between gap-4  lg:px-20 px-4 mt-12">

                {posts.map((post) => (
                    <div data-aos="fade-up"
                        data-aos-duration="2000" key={post.id} className="bg-white shadow-md p-4 w-full">
                        <img src={post.image} alt={post.title} className="mb-4 w-full  h-[200px]" />
                        <div className="flex items-center mb-3">
                            <span className="text-sm text-gray-500">{post.author}</span>
                            <span className="text-sm text-gray-500 mx-2">|</span>
                            <span className="text-sm text-gray-500">{post.date}</span>
                        </div>
                        <h2 className="text-xl font-bold mb-5">{post.title}</h2>
                        <p className="text-gray-600 mb-4">{post.content}</p>

                        <Link to={"About"}><p className='hover:text-aqua hover:ml-1'>Read More < ArrowForwardIcon /></p></Link>

                    </div>
                ))}
            </div>
        </div>



    );
};



export default BlogData;


{/* Grid wrapper */ }
{/* <div className="grid  lg:grid-cols-3">
   <div className=' h-[300px] bg-black'></div>
   <div className=' h-[300px] bg-red-400'></div>
   <div className='w-full h-[300px] bg-blue-400'></div>
   <div className='w-full h-[300px] bg-green-400'></div>
  </div>     */}
{/* Grid wrapper */ }