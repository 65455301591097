import React from 'react'
import Ecommarce2 from '../Image/Ecommarce.jpg';
import Ecommarce1 from '../Image/Ecommarce1.jpg';
import Testenomail from '../HomePage/Testenomail';

function Ecommarce() {
    return (
        <div data-aos="fade-up" data-aos-duration="2000" className="lg:px-20 px-5 mx-auto py-8 mt-12">
            <div className='text-center'>
                <h5 data-aos="fade-up"
                    data-aos-duration="2000" className='text-blue-500 text-aqua font-bold'></h5>
                <h2 data-aos="fade-up"
                    data-aos-duration="2000" className="text-3xl lg:text-4xl font-bold text-gray-900 dark:text-white mb-4">
                    E-Commerce Website
                </h2>
            </div>
            <div className="lg:flex lg:justify-between mt-20">

                <div className="lg:w-1/2 lg:mr-8 mb-8 lg:mb-0">
                    <h2 className="text-3xl lg:text-4xl font-bold text-gray-900 dark:text-white mb-4">
                        The Key to Boosting<span className='text-aqua'>Your Brand's Online Order </span>
                    </h2>
                    <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                    An e-commerce website is an online platform that allows businesses to sell products or services to customers over the internet. It provides a digital storefront where customers can browse products, make purchases, and complete transactions. Here are some key aspects of an e-commerce website:
                    </p>
                    <p className="text-gray-700 dark:text-gray-300 leading-relaxed mt-7">


                        <li>Product Listings: An e-commerce website typically features a catalog of products or services, including descriptions, images, prices, and availability. Products are organized into categories and subcategories for easy browsing.</li>
                        <li>Online Shopping Cart: Customers can add products to a virtual shopping cart as they browse the website. The shopping cart keeps track of selected items and allows customers to review, edit, and remove items before proceeding to checkout.</li>
                    </p>
                </div>
                <div className="lg:w-1/2">
                    <img
                        src={Ecommarce2}
                        alt="Company Image"
                        className="w-full lg:h-full rounded-md"
                        style={{ maxHeight: '400px' }}
                    />
                </div>
            </div>
            <div className="mt-[120px]">
                <div className="lg:flex lg:justify-between">
                    <div className="lg:w-1/2 lg:mr-8 mb-8 lg:mb-0">
                        <img
                            src={Ecommarce1}
                            alt="Company Image"
                            className="w-full lg:h-full rounded-md"
                            style={{ maxHeight: '400px' }}
                        />
                    </div>

                    <div className="lg:w-1/2">

                        <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                            {/* <h2 className='text-[25px]'> Security and SEO</h2> */}

                            <li>Checkout Process: The checkout process involves entering shipping and billing information, selecting a payment method, and confirming the order. E-commerce websites often offer multiple payment options, including credit/debit cards, digital wallets, and bank transfers.</li>
                            <li>Payment Gateway Integration: E-commerce websites integrate with payment gateways to securely process online payments. Payment gateways encrypt sensitive information to protect it from unauthorized access.</li>
                        </p>
                        <p className="text-gray-700 dark:text-gray-300 leading-relaxed mt-7">
                            {/* <h2 className='text-[25px]'>Deployment and Distribution:</h2> */}

                            <li>User Accounts: Customers can create accounts on e-commerce websites to store their information, track orders, and manage preferences. User accounts enhance the shopping experience by providing personalized recommendations and order history.</li>
                            <li>Customer Support: E-commerce websites provide customer support through various channels, such as live chat, email, and phone support. They also offer FAQs, tutorials, and help sections to assist customers with common issues.</li>

                        </p>
                    </div>
                </div>
                <Testenomail />
            </div>
        </div>
    )
}

export default Ecommarce