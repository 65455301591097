import React from 'react'
import webmain from '../Image/webmain.jpeg';
import webmain1 from '../Image/webmain1.png';
import Testenomail from '../HomePage/Testenomail';

function WebMatain() {
  return (
      <div data-aos="fade-up" data-aos-duration="2000" className="lg:px-20 px-5 mx-auto py-8 mt-12">
          <div className='text-center'>
              <h5 data-aos="fade-up"
                  data-aos-duration="2000" className='text-blue-500 text-aqua font-bold'></h5>
              <h2 data-aos="fade-up"
                  data-aos-duration="2000" className="text-3xl lg:text-4xl font-bold text-gray-900 dark:text-white mb-4">
                  Website Maintenance
              </h2>
          </div>
          <div className="lg:flex lg:justify-between mt-20">

              <div className="lg:w-1/2 lg:mr-8 mb-8 lg:mb-0">
                  <h2 className="text-3xl lg:text-4xl font-bold text-gray-900 dark:text-white mb-4">
                      Keep your website  <span className='text-aqua'>performance bugs free</span>
                  </h2>
                  <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                      AWebsite maintenance is the process of regularly checking your website for issues, updating content, and making necessary changes to ensure that it remains functional, secure, and up-to-date. It is essential for keeping your website running smoothly and providing a positive user experience. Here are some key aspects of website maintenance:
                  </p>
                  <p className="text-gray-700 dark:text-gray-300 leading-relaxed mt-7">
                      {/* <h2 className='text-[25px]'>Platform Selection:</h2> */}

                      <li>Content Updates: Regularly update your website content to keep it fresh and relevant. This includes updating text, images, videos, and other media.</li>
                      <li>Software Updates: Keep your website software up-to-date, including content management systems (CMS), plugins, and themes. Updates often include security patches and new features.</li>
                  </p>
              </div>
              <div className="lg:w-1/2">
                  <img
                      src={webmain}
                      alt="Company Image"
                      className="w-full lg:h-full rounded-md"
                      style={{ maxHeight: '400px' }}
                  />
              </div>
          </div>
          <div className="mt-[120px]">
              <div className="lg:flex lg:justify-between">
                  <div className="lg:w-1/2 lg:mr-8 mb-8 lg:mb-0">
                      <img
                          src={webmain1}
                          alt="Company Image"
                          className="w-full lg:h-full rounded-md"
                          style={{ maxHeight: '400px' }}
                      />
                  </div>

                  <div className="lg:w-1/2">

                      <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                          <h2 className='text-[25px]'> Security and SEO</h2>

                          <li>Security: Implement security measures to protect your website from malware, hackers, and other security threats. This includes using secure hosting, installing SSL certificates, and using strong passwords.</li>
                          <li>SEO Optimization: Continuously optimize your website for search engines to improve visibility and attract more organic traffic. This includes keyword optimization, meta tags, and sitemap management.</li>
                      </p>
                      <p className="text-gray-700 dark:text-gray-300 leading-relaxed mt-7">
                          <h2 className='text-[25px]'>Deployment and Distribution:</h2>

                          <li>Broken Links and Errors: Regularly check for broken links, missing images, and other errors that may affect the usability of your website.</li>
                          <li>Legal Compliance: Ensure that your website complies with relevant laws and regulations, such as GDPR for privacy protection and ADA for accessibility.</li>

                      </p>
                  </div>
              </div>
              <Testenomail />
          </div>
      </div>
  )
}

export default WebMatain