export  const testimonial = [
    {
        name: 'Abhimanyu Agarwal',
        role: 'CEO(FOUNDER)',
        image: '/Image2/html.png',
        state: 'Abhimanyu Agarwal  are the founders of Devil Infotech, establishing the company with a vision to innovate and excel in the IT industry.'

    },
    {
        name: 'Prakhar Tripathi',
        role: 'DIRECTOR',
        image: '/Image2/css.png',
        state: 'Prakhar Tripathi serves as the Director of Devil Infotech, overseeing the company operations and strategic direction. He plays a key role in guiding Devil Infotech.'

    },
    {
        name: 'Awinash sinha',
        role: 'HR',
        image: '/Image2/javaScript.png',
        state: 'It seems like you mentioned Awinash Sinha as the HR of Devil Infotech, an IT company. If you have any specific questions or need assistance related to HR.'
    },
    {
        name: 'Deepak kumar',
        role: 'TEAM MEMBER',
        image: '/Image2/Bootstarp.png',
        state: 'Deepak Kumar is a valuable team member at Devil Infotech, responsible for handling various tasks within the company. His contributions play a crucial role.'


    },
    {
        name: 'Deepak kumar',
        role: 'TEAM MEMBER',
        image: '/Image2/Jquery.png',
        state: 'Deepak Kumar is a valuable team member at Devil Infotech, responsible for handling various tasks within the company. His contributions play a crucial role.'


    },
    {
        name: 'Deepak kumar',
        role: 'TEAM MEMBER',
        image: '/Image2/Java.png',
        state: 'Deepak Kumar is a valuable team member at Devil Infotech, responsible for handling various tasks within the company. His contributions play a crucial role.'


    },
    {
        name: 'Deepak kumar',
        role: 'TEAM MEMBER',
        image: '/Image2/Python.png',
        state: 'Deepak Kumar is a valuable team member at Devil Infotech, responsible for handling various tasks within the company. His contributions play a crucial role.'


    },
    {
        name: 'Deepak kumar',
        role: 'TEAM MEMBER',
        image: '/Image2/React.png',
        state: 'Deepak Kumar is a valuable team member at Devil Infotech, responsible for handling various tasks within the company. His contributions play a crucial role.'


    },
    {
        name: 'Deepak kumar',
        role: 'TEAM MEMBER',
        image: '/Image2/Node.png',
        state: 'Deepak Kumar is a valuable team member at Devil Infotech, responsible for handling various tasks within the company. His contributions play a crucial role.'


    },
    // {
    //     name: 'Deepak kumar',
    //     role: 'TEAM MEMBER',
    //     image: '/Image2/Laravel.png',
    //     state: 'Deepak Kumar is a valuable team member at Devil Infotech, responsible for handling various tasks within the company. His contributions play a crucial role.'


    // },
    {
        name: 'Deepak kumar',
        role: 'TEAM MEMBER',
        image: '/Image2/vew.png',
        state: 'Deepak Kumar is a valuable team member at Devil Infotech, responsible for handling various tasks within the company. His contributions play a crucial role.'


    },
    {
        name: 'Deepak kumar',
        role: 'TEAM MEMBER',
        image: '/Image2/Dotnet.png',
        state: 'Deepak Kumar is a valuable team member at Devil Infotech, responsible for handling various tasks within the company. His contributions play a crucial role.'


    },
    {
        name: 'Deepak kumar',
        role: 'TEAM MEMBER',
        image: '/Image2/Angular.png',
        state: 'Deepak Kumar is a valuable team member at Devil Infotech, responsible for handling various tasks within the company. His contributions play a crucial role.'


    },


];