import React from 'react';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Link } from 'react-router-dom';
import internship from '../Image/internship.webp'

const InternshipDet = () => {
    return (
        <div data-aos="fade-up" data-aos-duration="2000" className="">
            <div className="lg:px-20 px-4 mx-auto py-8 mt-12">
                <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-between">
                    <div className="lg:w-1/2 lg:mr-8 mb-8 lg:mb-0">

                        <h3 data-aos="fade-up"
                            data-aos-duration="2000" className="text-3xl lg:text-4xl font-bold text-gray-900 dark:text-white mb-4">
                            Dear Student,
                        </h3>
                        <p data-aos="fade-up"
                            data-aos-duration="2000" className="text-gray-700 dark:text-gray-300 leading-relaxed">
                            We are thrilled to announce that Devil Infotech is offering an Internship Program for IT students. As a prominent IT company, we are dedicated to providing exceptional opportunities for students to enhance their skills and gain practical experience in the field.
                        </p>
                        <p data-aos="fade-up"
                            data-aos-duration="2000" className="text-gray-700 dark:text-gray-300 leading-relaxed mt-7">
                            During this internship, you will have the chance to work on real-world projects alongside our experienced developers. This program is designed to provide you with a comprehensive understanding of software and website development and help you develop practical skills that will be valuable for your future career.
                        </p>
                        <p data-aos="fade-up"
                            data-aos-duration="2000" className="text-gray-700 dark:text-gray-300 leading-relaxed mt-7">
                           To apply for this program, you must be currently enrolled in an IT-related course in your college. We are looking for students who are passionate about technologies, eager to learn, and committed to delivering high-quality work.
                        </p>
                        <p data-aos="fade-up"
                            data-aos-duration="2000" className="text-gray-700 dark:text-gray-300 leading-relaxed mt-7">
                           We believe that this internship program will be a rewarding experience for you and will provide you with valuable insights into the world of IT. We look forward to welcoming you to the Devil Infotech team and helping you grow and succeed in your career.
                        </p>
                        <b data-aos="fade-up"
                            data-aos-duration="2000" className="font-bold text-gray-900 dark:text-white mt-10">
                            Here are the details of the internship program at Devil Infotech:
                        </b>
                        <div className='leading-10'>
                        <p><b>Minimum Duration:</b> 1 Year</p>
                        <p><b>Eligibility:</b> Students pursuing an IT-related course</p>
                        <p><b>Preferred Skills:</b> HTML & CSS, Bootstrap, JavaScript, jQuery, PHP, Laravel, CodeIgniter, Manual Testing (QA), etc.</p>
                        <p><b>Location:</b> Devil Infotech (Ahmedabad)</p>
                        </div>
                        
                    </div>
                    <div data-aos="fade-up"
                        data-aos-duration="3000" className="lg:w-1/3">
                        <img
                            src={internship}
                            alt="Company Image"
                            className=" md:w-[400px] "
                            style={{ height: '400px', width: '600px' }}
                        />
                    </div>
                    
                </div>
                <p className='mt-7'>To apply for this program, please fill out the form with your latest resume. Shortlisted candidates will be invited for an interview. This is an excellent opportunity to kick-start your career in software and website development and gain practical experience.</p>
                <p className='mt-4'>We look forward to hearing from you and working together to build a better future.</p>
            </div>
        </div>
    );
};

export default InternshipDet;
