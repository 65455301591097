import React from 'react';
import { Image } from 'antd';

const Popup = () => {
    const images = [
        {
            image: 'https://cdn.dribbble.com/users/2659777/screenshots/6100524/3.jpg?resize=400x0',
            
        },
        {
            image: 'https://code4berry.com/img/works/ecommerce.jpg',
           
        },
        {

            image: 'https://s3-alpha.figma.com/hub/file/3999559028/89ae9067-9994-4ac8-9ed2-a90b2691e1ab-cover.png',

        },
        {

            image: 'https://i.pinimg.com/originals/aa/a6/f0/aaa6f0ac5501503db355ca97c1e6780b.jpg',

        },
    ];

    return (
        <div>
            <h5 className="mb-5 text-center text-gray-500 sm:text-xl dark:text-gray-400 font-bold text-aqua">BLOG PORTFOLIO</h5>
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white text-center">Latest Portfolio</h2>
            <div className='grid grid-cols-1 w-full h-full xl:grid-cols-4 xl:px-20 px-5 sm:grid-cols-3 gap-5 mt-10'>
                {images.map((item, i) => (
                    <Image
                    height={300}
                        key={i}
                        className=' h-[300px] w-full cursor-pointer'
                        alt={item.name}
                        src={item.image}
                    />
                ))}
               
            </div>
        </div>
    );
};

export default Popup;
