import React from "react";
import logo4 from "../Image/tralogo.png";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <footer className="lg:px-20 px-4 z-10 bg-black text-white pb-10 pt-20 dark:bg-dark lg:pb-20 lg:pt-[120px]">
        <div className="">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4 sm:w-2/3 lg:w-3/12">
              <div className="mb-10 w-full">
                <div className="flex">
                <a href="/" className="mb-6 inline-block max-w-[50px]">
                  <img
                    src={logo4}
                    alt="logo"
                    className="max-w-full dark:hidden"
                  />

                </a>
                <p className="mt-3">Devil Infotech</p>
                </div>
                <p className="mb-7 text-base text-body-color dark:text-dark-6">
                hey strive to provide cutting-edge solutions and services to their clients, aiming to lead in the digital transformation landscape.
                </p>
                <p className="flex items-center text-sm font-medium text-dark dark:text-white">
                  <span className="mr-3 text-primary">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_941_15626)">
                        <path
                          d="M15.1875 19.4688C14.3438 19.4688 13.375 19.25 12.3125 18.8438C10.1875 18 7.84377 16.375 5.75002 14.2813C3.65627 12.1875 2.03127 9.84377 1.18752 7.68752C0.250019 5.37502 0.343769 3.46877 1.43752 2.40627C1.46877 2.37502 1.53127 2.34377 1.56252 2.31252L4.18752 0.750025C4.84377 0.375025 5.68752 0.562525 6.12502 1.18752L7.96877 3.93753C8.40627 4.59378 8.21877 5.46877 7.59377 5.90627L6.46877 6.68752C7.28127 8.00002 9.59377 11.2188 13.2813 13.5313L13.9688 12.5313C14.5 11.7813 15.3438 11.5625 16.0313 12.0313L18.7813 13.875C19.4063 14.3125 19.5938 15.1563 19.2188 15.8125L17.6563 18.4375C17.625 18.5 17.5938 18.5313 17.5625 18.5625C17 19.1563 16.1875 19.4688 15.1875 19.4688ZM2.37502 3.46878C1.78127 4.12503 1.81252 5.46877 2.50002 7.18752C3.28127 9.15627 4.78127 11.3125 6.75002 13.2813C8.68752 15.2188 10.875 16.7188 12.8125 17.5C14.5 18.1875 15.8438 18.2188 16.5313 17.625L18.0313 15.0625C18.0313 15.0313 18.0313 15.0313 18.0313 15L15.2813 13.1563C15.2813 13.1563 15.2188 13.1875 15.1563 13.2813L14.4688 14.2813C14.0313 14.9063 13.1875 15.0938 12.5625 14.6875C8.62502 12.25 6.18752 8.84377 5.31252 7.46877C4.90627 6.81252 5.06252 5.96878 5.68752 5.53128L6.81252 4.75002V4.71878L4.96877 1.96877C4.96877 1.93752 4.93752 1.93752 4.90627 1.96877L2.37502 3.46878Z"
                          fill="currentColor"
                        />
                        <path
                          d="M18.3125 8.90633C17.9375 8.90633 17.6563 8.62508 17.625 8.25008C17.375 5.09383 14.7813 2.56258 11.5938 2.34383C11.2188 2.31258 10.9063 2.00008 10.9375 1.59383C10.9688 1.21883 11.2813 0.906333 11.6875 0.937583C15.5625 1.18758 18.7188 4.25008 19.0313 8.12508C19.0625 8.50008 18.7813 8.84383 18.375 8.87508C18.375 8.90633 18.3438 8.90633 18.3125 8.90633Z"
                          fill="currentColor"
                        />
                        <path
                          d="M15.2187 9.18755C14.875 9.18755 14.5625 8.93755 14.5312 8.56255C14.3437 6.87505 13.0312 5.56255 11.3437 5.3438C10.9687 5.31255 10.6875 4.93755 10.7187 4.56255C10.75 4.18755 11.125 3.9063 11.5 3.93755C13.8437 4.2188 15.6562 6.0313 15.9375 8.37505C15.9687 8.75005 15.7187 9.0938 15.3125 9.1563C15.25 9.18755 15.2187 9.18755 15.2187 9.18755Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_941_15626">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span><a href="tel:9905873940">+91 9905873940</a></span>
                </p>
                <div className="mt-2">
                  <EmailIcon />
                  <span> &nbsp;<a href="mailto:info@devilinfotech.com">info@devilinfotech.com</a></span><br />

                </div>
                <div className="mt-2">
                  <PictureAsPdfIcon />
                  <span> &nbsp;<a target="_blank" href="https://drive.google.com/file/d/1G4ph3KKf3O5v4vySdzqNqYKEXWDiIUUU/view?usp=sharing">Brochure Download</a></span>
                </div>
              </div>
            </div>

            <LinkGroup header="Page">
              <NavLink link="/" label="Home" />
              <NavLink link="/about" label="About Us" />
              <NavLink link="/service" label="Our Service" />
              <NavLink link="/blog" label="Latest Blog" />
              <NavLink link="/internship" label="Apply Internship" />
              <NavLink link="/contect" label="Contact Us" />
            </LinkGroup>
            <LinkGroup header="Services">
              <NavLink link="/domin" label="Domain & Hosting" />
              <NavLink link="/webdevelop" label="Web Development" />
              <NavLink link="/appdeve" label="App Development" />
              <NavLink link="/webmatain" label="Web Maintenance" />
              <NavLink link="/digital" label="Digital Marketing" />
              <NavLink link="/ecommarce" label="E-Commerce Website" />
            </LinkGroup>
            <LinkGroup header="Products">
              <NavLink link="/maintance" label="Clinic Management" />
              <NavLink link="/maintance" label="Production Management" />
              <NavLink link="/maintance" label="Taxi Booking" />
              <NavLink link="/maintance" label="Business Listing" />
              <NavLink link="/maintance" label="College Management" />
              <NavLink link="/maintance" label="Shop Management" />
            </LinkGroup>

            <div className="w-full px-4 sm:w-1/2 lg:w-3/12">
              <div className="mb-10 w-full">
                <h4 className="mb-9 text-lg font-semibold text-dark dark:text-white">
                  Follow Us On
                </h4>
                <div className="mb-6 flex items-center text-white">
                  <a href="https://www.facebook.com/"><div className="border border-white mr-5 rounded-full p-1"><FacebookIcon /></div></a>
                  <a href="https://www.instagram.com/devilinfotech/"><div className="border border-white mr-5  rounded-full p-1"><InstagramIcon />
                  </div></a>
                  <a href="https://twitter.com/devilinfotech?t=lhbvDCPaid3k0l7BLj9g2w&s=08"><div className="border border-white mr-5  rounded-full p-1"><TwitterIcon /></div></a>
                  <a href="https://www.linkedin.com/in/devil-infotech-59162a305/"><div className="border border-white  rounded-full p-1"><LinkedInIcon /></div></a>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div>
          <span className="absolute bottom-0 left-0 z-[-1]">
            <svg
              width={217}
              height={229}
              viewBox="0 0 217 229"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M-64 140.5C-64 62.904 -1.096 1.90666e-05 76.5 1.22829e-05C154.096 5.49924e-06 217 62.904 217 140.5C217 218.096 154.096 281 76.5 281C-1.09598 281 -64 218.096 -64 140.5Z"
                fill="url(#paint0_linear_1179_5)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1179_5"
                  x1="76.5"
                  y1={281}
                  x2="76.5"
                  y2="1.22829e-05"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#3056D3" stopOpacity="0.08" />
                  <stop offset={1} stopColor="#C4C4C4" stopOpacity={0} />
                </linearGradient>
              </defs>
            </svg>
          </span>

        </div>
        <p className="text-center"> Copyright &copy; 2024 Devil Infotech All Rights Reserved.</p>
      </footer>
    </>
  );
};

export default Footer;

const LinkGroup = ({ children, header }) => {
  return (
    <>
      <div className="w-full px-4 sm:w-1/2 lg:w-2/12">
        <div className="mb-10 w-full">
          <h4 className="mb-9 text-lg font-semibold text-dark dark:text-white">
            {header}
          </h4>
          <ul className="space-y-3">{children}</ul>
        </div>
      </div>
    </>
  );
};

const NavLink = ({ link, label }) => {
  return (
    <li>
      <a
        href={link}
        className="inline-block text-base leading-loose text-body-color hover:text-primary dark:text-dark-6"
      >
        {label}
      </a>
    </li>
  );
};
