import React from 'react';

const PricingCard = ({ title, description, price, features, buttonLabel }) => {
  return (
    <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg xl:p-8 shadow-md shadow-black">
      <h3 className="mb-4 text-2xl font-semibold text-aqua">{title}</h3>
      <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">{description}</p>
      <div className="flex justify-center items-baseline my-8">
        <span className="mr-2 text-5xl font-extrabold">{price}</span>
        <span className="text-gray-500 dark:text-gray-400">Year</span>
      </div>
      <ul role="list" className="mb-8 space-y-4 text-left">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center space-x-3">
            <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
            </svg>
            <span>{feature}</span>
          </li>
        ))}
      </ul>
      <a href="#" className="text-black bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white dark:focus:ring-primary-900 bg-aqua">{buttonLabel}</a>
    </div>
  );
};

const PricingSection = () => {
  return (
    <section className="bg-white dark:bg-gray-900 mt-20">
      <div className=" mx-auto px-4 lg:px-20">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <h5 className="mb-5 text-gray-500 sm:text-xl dark:text-gray-400 font-bold text-aqua">PRICING PLANS</h5>
          <h5 className="mb-4 text-4xl tracking-tight font-extrabold ">Choose Your Pricing Paln</h5>
        </div>
        <div data-aos="fade-up"
          data-aos-duration="2000" className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
          <PricingCard
            title="Basic Plan"
            description="Best option for personal use & for your next project."
            price="₹12999"
            features={[
              "Individual configuration",
              "No setup, or hidden fees",
              "Team size: 1 developer",
              "Premium support: 6 months",
              "Free updates: 6 months"
            ]}
            buttonLabel="Get started"
          />
          <PricingCard
            title="Stander Plan"
            description="Relevant for multiple users, extended & premium support."
            price="₹15999"
            features={[
              "Individual configuration",
              "No setup, or hidden fees",
              "Team size: 10 developers",
              "Premium support: 24 months",
              "Free updates: 24 months"
            ]}
            buttonLabel="Get started"
          />
          <PricingCard
            title="Advance Plan"
            description="Best for large scale uses and extended redistribution rights."
            price="₹19999"
            features={[
              "Individual configuration",
              "No setup, or hidden fees",
              "Team size: 100+ developers",
              "Premium support: 36 months",
              "Free updates: 36 months"
            ]}
            buttonLabel="Get started"
          />
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
