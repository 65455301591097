import React from "react";
import AppDeve from '../Image/Appdeve.jpg';
import Webdesign from '../Image/Webdesign.png';
import ecommrce from '../Image/E-commarce.png';
import digital from '../Image/Digitalmar.png';
import webappl from '../Image/Webapplic.png';
import webmain from '../Image/Webmain.png';
import { Link } from "react-router-dom";

const Service = () => {
  return (
    <section className="lg:pb-[90px] lg:pt-[120px] lg:px-20">
      <div className="">
        <div className="flex flex-wrap px-5">
          <div className="w-full px-4">
            <div className="mx-auto mb-12 max-w-[510px] text-center lg:mb-20">
              
               <h5 className="mb-5 text-center text-gray-500 sm:text-xl dark:text-gray-400 font-bold text-aqua">OUR SERVICES</h5>
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white text-center">What We Do</h2>
            </div>
          </div>
        </div>
        <div className=" flex flex-wrap">
          <ServiceCard
            title="Web Design"
            details="
            Web design refers to the process of creating the visual appearance and layout of a website. It involves designing the look and feel of the website, including its colors."
            icon={Webdesign}
            link="/webdesign"

            />
          <ServiceCard
            title="App Development"
            details="App development refers to the process of creating software applications that run on mobile devices, such as smartphones and tablets, or desktop"
            icon={AppDeve}
            link="/appdeve"
            
            />
          <ServiceCard
            title="E-Commerce Website"
            details="An e-commerce website is a website that allows businesses to sell products or services online. It provides a platform for customers to browse."
            icon={ecommrce}
            link="/ecommarce"
            
            />
          <ServiceCard
            title="Digital Marketing"
            details="Digital marketing refers to the use of digital channels, such as websites, social media, search engines, email, and mobile apps, to promote products or services and connect with customers. "
            icon={digital}
            link="/digital"
            
            />
          <ServiceCard
            title="Web Maintenance"
            details="
            Web maintenance refers to the ongoing process of keeping a website up-to-date, secure, and running smoothly. It involves regular monitoring, updating, and optimizing of website components"
            icon={webappl}
            link="/webmatain"
            
            />
          <ServiceCard
            title="WordPress website"
            details="
            A WordPress website is a website that is the built using WordPress, which is a popular content management system (CMS).WordPress is a powerful and versatile platform for creating."
            icon={webmain}
            link="wordpress"
           
          />
        </div>
      </div>
    </section>
  );
};

export default Service;

const ServiceCard = ({ icon, title, details, link }) => {
  return (
    <>
      <div className="w-full  md:w-1/2 lg:w-1/3 text-center px-5  ">
        <Link to={link}><div data-aos="fade-up" 
          data-aos-duration="2000" className="mb-9  cursor-pointer rounded-[3px] bg-white hover:border hover:border-dashed hover:border-aqua p-10 md:px-7 shadow-md :px-10">
            <div className="">
            <img src={icon} alt={title} className="mx-auto mb-4" style={{ width: '100px', height: '100px' }} />
          <h4 data-aos="fade-up"
          data-aos-duration="2000" className="mb-[14px] text-2xl font-semibold text-dark dark:text-white">
            {title}
          </h4>
          <p data-aos="fade-up"
          data-aos-duration="2000" className="text-body-color dark:text-dark-6">{details}</p>
          </div>
        </div></Link>
      </div>
    </>
  );
};
