import React from 'react'
import Home from '../HomePage/Home'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from '../About/About';
import Footer from '../Footer/Footer';
import ErrorFile from '../404Page/ErrorFile';
import Service from '../Service/Service';
import Blog from '../Blog/Blog';
import Contect from '../Contect/Contect';
import Maintance from '../404Page/Maintance';
import Domin from '../Footer_Service/Domin';
import WebDevelop from '../Footer_Service/WebDevelop';
import Appdeve from '../Footer_Service/Appdeve';
import WebMatain from '../Footer_Service/WebMatain';
import Digital from '../Footer_Service/Digital';
import Ecommarce from '../Footer_Service/Ecommarce';
import Nav from '../Navigation/Navbar.jsx';
import Internship from '../Internship/Internship.jsx';
import LoginForm from '../Admin/LoginForm.jsx';
import Webdesign from '../Footer_Service/Webdesign.jsx';
import Wordpress from '../Footer_Service/Wordpress.jsx';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CustomerRouters() {
    return (
        <div>
            <BrowserRouter>
                <Nav />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/service" element={<Service />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/contect" element={<Contect />} />
                    <Route path='/*' element={<ErrorFile />} />
                    <Route path='/maintance' element={<Maintance />} />
                    <Route path='/domin' element={<Domin />} />
                    <Route path='/webdevelop' element={<WebDevelop />} />
                    <Route path='/appdeve' element={<Appdeve />} />
                    <Route path='/webmatain' element={<WebMatain />} />
                    <Route path='/digital' element={<Digital />} />
                    <Route path='/ecommarce' element={<Ecommarce />} />
                    <Route path='/internship' element={<Internship />} />
                    <Route path='/loginform' element={<LoginForm />} />
                    <Route path='/webdesign' element={<Webdesign />} />
                    <Route path='/wordpress' element={<Wordpress />} />
                </Routes>
            </BrowserRouter>
            <Footer />
            <ToastContainer />
        </div>
    )
}

export default CustomerRouters