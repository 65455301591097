import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';


function ContactSection() {
    const form = useRef();
    const [formData,setFormData] = useState({
        fname:"",
        email:"",
        phone:"",
        message:""
    })
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        // console.log("formdata", formData)
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_z4dpb6y', 'template_wttisgm', form.current, {
            publicKey: '_26M9UquTQuAjqJJV',
          })
            .then((result) => {
                console.log("succesflly");
                toast.success('Submit Succesfully');
                // Optionally, you can show a success message to the user
            }, (error) => {
                console.log(error.text);
                toast.error('Something want warong');
                // Optionally, you can show an error message to the user
            });

        e.target.reset(); // Reset the form after submission
    };

    return (
        <section className="bg-white dark:bg-gray-900 mt-20">
            <div data-aos="fade-up" data-aos-duration="2000" className="lg:px-20 px-4 py-12 mx-auto ">
                <h5 className="mb-5 text-center text-gray-500 sm:text-xl dark:text-gray-400 font-bold text-aqua">GET IN TOUCH</h5>
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white text-center">Need The Fill Free To Contact Us</h2>
                <div data-aos="fade-up" data-aos-duration="2000" className="lg:flex lg:items-center lg:-mx-6 mt-12">

                    <div data-aos="fade-up" data-aos-duration="2000" className="lg:w-1/2 lg:mx-6">
                        <h1 className="text-2xl font-semibold text-gray-800 capitalize dark:text-white lg:text-3xl">
                            Contact us for <br /> more info
                        </h1>
                        <div className='mt-8'>
                            <p>
                                Devil Infotech, a leading IT company, offers innovative solutions to meet your technology needs. Our "Contact Us" form is designed for easy communication, ensuring a prompt response to your inquiries.
                            </p>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="2000" className="mt-6 space-y-8 md:mt-8">
                            <p data-aos="fade-up" data-aos-duration="2000" className="flex items-start -mx-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mx-2 text-blue-500 dark:text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <span className="mx-2 text-gray-700 truncate w-72 dark:text-gray-400">
                                    <a href="https://maps.app.goo.gl/aMFSd1yqGaJ8yf9v6">Ahmedabad, Gujarat, 380061</a>
                                </span>
                            </p>
                            <p data-aos="fade-up" data-aos-duration="2000" className="flex items-start -mx-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mx-2 text-blue-500 dark:text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                </svg>
                                <span className="mx-2 text-gray-700 truncate w-72 dark:text-gray-400">
                                    <a href="tel:9905873940" >+91 9905873940</a>
                                </span>
                            </p>
                            <p data-aos="fade-up" data-aos-duration="2000" className="flex items-start -mx-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mx-2 text-blue-500 dark:text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                </svg>
                                <span className="mx-2 text-gray-700 truncate w-72 dark:text-gray-400">
                                    <a href="mailto:info@devilinfotech.com">info@devilinfotech.com</a>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="2000" className="mt-8 lg:w-1/2 lg:mx-6">
                        <div className="w-full px-8 py-10 mx-auto overflow-hidden bg-white rounded-lg shadow-2xl dark:bg-gray-900 lg:max-w-xl shadow-gray-300/50">
                            <form ref={form} onSubmit={handleSubmit}>
                                <div className="flex-1">
                                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        name='fname'
                                        placeholder="Name"
                                        className="w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 "
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="flex-1 mt-6">
                                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        name='email'
                                        placeholder="Email"
                                        className="w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 "
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="flex-1 mt-6">
                                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                                        Phone
                                    </label>
                                    <input
                                        type="tel"
                                        name='phone'
                                        placeholder="Phone"
                                        className="w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 "
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="w-full mt-6">
                                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                                        Message
                                    </label>
                                    <textarea
                                        name='message'
                                        className="block w-full mt-2 px-5 py-3 bg-white border border-gray-200 h-20"
                                        placeholder="Message"
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <button type='submit' className="w-full px-6 py-3 mt-6 text-sm font-medium tracking-wide text-black   bg-aqua focus:ring-opacity-50">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactSection;
