import React from 'react';
import Dominimage from '../Image/Domin.jpeg';
import HostingImage from '../Image/hosting.png';
import Testenomail from '../HomePage/Testenomail';

const Domin = () => {
    return (
        <div data-aos="fade-up" data-aos-duration="2000" className="lg:px-20 px-5 mx-auto py-8 mt-12">
            <div className='text-center'>
                <h5 data-aos="fade-up"
                    data-aos-duration="2000" className='text-blue-500 text-aqua font-bold'></h5>
                <h2 data-aos="fade-up"
                    data-aos-duration="2000" className="text-3xl lg:text-4xl font-bold text-gray-900 dark:text-white mb-4">
                    Domin And Hosting
                </h2>
            </div>
            <div className="lg:flex lg:justify-between mt-20">

                <div className="lg:w-1/2 lg:mr-8 mb-8 lg:mb-0">
                    <h2 className="text-3xl lg:text-4xl font-bold text-gray-900 dark:text-white mb-4">
                        Domin
                    </h2>
                    <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                        In Devil Infotech, the Domain & Hosting department is responsible for managing all aspects related to domain names and web hosting services. This includes registering new domains, renewing existing ones, and ensuring that all client websites are hosted securely and efficiently. The team works closely with clients to understand their hosting needs and provide them with the best solutions available. They also monitor server performance, troubleshoot any issues that arise, and ensure that all websites are running smoothly. Overall, the Domain & Hosting team plays a crucial role in ensuring the online presence of clients is reliable and effective.
                    </p>
                    <p className="text-gray-700 dark:text-gray-300 leading-relaxed mt-7">
                        Our mission is to empower businesses with technology-driven solutions that enhance efficiency, productivity, and growth. We aim to be a trusted partner for our clients, offering tailored services that align with their unique goals and objectives.
                    </p>
                </div>
                <div className="lg:w-1/2">
                    <img
                        src={Dominimage}
                        alt="Company Image"
                        className="w-full lg:h-auto rounded-md"
                        style={{ maxHeight: '400px' }}
                    />
                </div>
            </div>
            <div className="mt-[120px]">
                <div className="lg:flex lg:justify-between">
                    <div className="lg:w-1/2 lg:mr-8 mb-8 lg:mb-0">
                        <img
                            src={HostingImage}
                            alt="Company Image"
                            className="w-full lg:h-auto rounded-md"
                            style={{ maxHeight: '400px' }}
                        />
                    </div>

                    <div className="lg:w-1/2">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-900 dark:text-white mb-4">
                            Hosting
                        </h2>
                        <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                            Hosting refers to the service of providing storage space and access for websites and other online content. When you create a website, you need a place to store all the files, images, and other content that make up your site. Hosting companies offer this service by providing servers that store your website's files and make them accessible to users on the internet.
                        </p>
                        <p className="text-gray-700 dark:text-gray-300 leading-relaxed mt-7">
                            There are different types of hosting services available, including shared hosting, where multiple websites share a single server; VPS (Virtual Private Server) hosting, where a single server is divided into multiple virtual servers, each hosting its own website; and dedicated hosting, where you have an entire server dedicated to hosting your website. Each type of hosting has its own advantages and is suitable for different types of websites based on their size, traffic, and resource requirements.
                        </p>
                    </div>
                </div>
                <Testenomail />
            </div>
        </div>
    );
};

export default Domin;
