import { FaUsers } from "react-icons/fa";
import { IoHappySharp } from "react-icons/io5";

export const data = [
    {
        id: 2,
        icon: <FaUsers className="h-8 w-8 text-white" />,
        name: "Satisfied Clients",
        data: "5",
    },
    {
        id: 3,
        icon: <IoHappySharp className="h-8 w-8 text-white" />,
        name: "Project Completed",
        data: "10",
    },
    {
        id: 4,
        icon: <FaUsers className="h-8 w-8 text-white" />,
        name: "Years of Experience",
        data: "1",
    },
];
