import React from 'react'
import ServiceSection from '../HomePage/ServiceSection'
import Testenomail from '../HomePage/Testenomail'
import TestenomilSection from '../HomePage/TestenomilSection'

function Service() {
    return (
        <div>
            <ServiceSection />
            <TestenomilSection />
            <Testenomail /> 
        </div>
    )
}

export default Service