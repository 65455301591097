import React, { useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export function HomeCaresole() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="relative h-screen overflow-hidden">
        <video
          autoPlay
          loop
          muted
          className="absolute inset-0 object-cover w-full h-full"
          src="/Image/backgroun-video.mp4"
          type="video/mp4"
        />
        <div className="absolute inset-0 bg-black opacity-50" />
        <div className="h-screen flex flex-col justify-center items-center relative z-10">
          <div className="text-center">
            <h2 className="text-aqua text-3xl mt-8">Crafting Your</h2>
            <h2 className="text-3xl text-white lg:text-4xl text-gray-900 dark:text-white mt-8 font-black">
              Web Destiny With Stunning Designs
            </h2>
            <p className="text-xl text-white">
              We are a crew of builders, designers & investors building disruptive
              products in Web3 and empowering founders along the way
            </p>
          </div>
          <div className="p-4 mt-15">
            <button
              onClick={handleOpen}
              color="teal"
              size="lg"
              ripple="light"
              className="hover:bg-black hover:text-white transition duration-300 bg-aqua p-3"
            >
              Request A Quote<ArrowForwardIcon />
            </button>
          </div>
        </div>
      </div>
      <Modal open={open} onClose={handleClose}>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white w-full max-w-lg md:max-w-xl lg:max-w-2xl p-8 rounded-md">
          <Typography variant="h5" className='bg-aqua p-3'>
            Request A Quote 
            <button onClick={handleClose} className='ml-auto text-black'><CloseIcon/></button>
          </Typography>
          <div className="flex-1">
            <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
              Name<span className='text-red'>*</span>
            </label>
            <input
              type="text"
              name='name'
              placeholder="Name"
              className="w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 "
            />
          </div>
          <div className="flex-1 mt-6">
            <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
              Email<span className='text-red'>*</span>
            </label>
            <input
              type="email"
              name='email'
              placeholder="Email"
              className="w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 "
            />
          </div>
          <div className="flex-1 mt-6">
            <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
              Phone<span className='text-red'>*</span>
            </label>
            <input
              type="tel"
              name='phone'
              placeholder="Phone"
              className="w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 "
            />
          </div>
          <button type='submit' className='w-full mt-5 rounded-lg bg-aqua p-3'>Submit</button>
        </div>
      </Modal>
    </>
  );
}

export default HomeCaresole;
