import React from "react";
import CountUp from "react-countup";
import { data } from "../../constants/contupsection";

const ContupSection = () => {


    return (
        <div className="w-full py-10 lg:px-20 px-4 mt-20">
            <section className="text-gray-700 body-font">
                {/* <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <h5 className="mb-5 text-gray-500 sm:text-xl dark:text-gray-400 font-bold text-aqua">PRICING PLANS</h5>
          <h5 className="mb-4 text-4xl tracking-tight font-extrabold ">Choose Your Pricing Paln</h5>
        </div> */}
                <div className="grid xl:grid-cols-3 md:grid-cols-3 -m-4 mt-20">
                    {data.map((item) => (
                        <div
                            key={item.id}
                            className="p-4 w-full"
                            data-aos="fade-up"
                        >
                            <div className=" shadow shadow-black bg-white px-4 py-6 rounded-lg transform transition duration-500 hover:scale-110">
                                {/* <div className="flex justify-center items-center">
                                    <span className="p-3 bg-black">{item.icon}</span>
                                </div> */}
                                <h2 className="title-font text-center font-semibold py-1 text-black text-3xl">
                                    <CountUp start={0} delay={2} duration={20} end={item.data} />
                                </h2>
                                <p className="leading-relaxed text-center text-lg text-black font-semibold">
                                    {item.name}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default ContupSection;
