import React from 'react'
import BlogData from '../HomePage/BlogData'

function Blog() {
  return (
    <div>
        <BlogData />
    </div>
  )
}

export default Blog