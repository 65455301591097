import React from 'react'
import HomeCaresole from './HomeCaresole'
import AboutHome from './AboutHome'
import Faq from './Faq'
import PricingSection from './PricingSection'
import SlideSection from './SlideSection'
import TeamSection from './TeamSection'
import BlogSection from './BlogSection'
import BlogData from './BlogData'
import Testenomail from './Testenomail'
import ServiceSection from './ServiceSection'
import ContectSecion from './ContectSecion'
import TestenomilSection from './TestenomilSection'
import ContupSection from './ContupSection'
import Technology from './Technology'

function Home() {
  return (
    <div>
      <SlideSection />
      <HomeCaresole />
      <AboutHome />
      <ContupSection />
      <ServiceSection />
      <PricingSection />
      <ContectSecion/>
      <TestenomilSection />
      <TeamSection />
      {/* <Testenomail /> */}
      <BlogData />
      <Testenomail/>
      <Technology/>
      <Faq />
    </div>

  )
}

export default Home