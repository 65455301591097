import React from 'react'
import Digital1 from '../Image/Digital.png';
import Digital2 from '../Image/Digital2.jpg';
import Testenomail from '../HomePage/Testenomail';

function Digital() {
  return (
    <div data-aos="fade-up" data-aos-duration="2000" className="lg:px-20 px-5 mx-auto py-8 mt-12">
    <div className='text-center'>
        <h5 data-aos="fade-up"
            data-aos-duration="2000" className='text-blue-500 text-aqua font-bold'></h5>
        <h2 data-aos="fade-up"
            data-aos-duration="2000" className="text-3xl lg:text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Digital Marketing
        </h2>
    </div>
    <div className="lg:flex lg:justify-between mt-20">

        <div className="lg:w-1/2 lg:mr-8 mb-8 lg:mb-0">
            <h2 className="text-3xl lg:text-4xl font-bold text-gray-900 dark:text-white mb-4">
            The Key to Boosting<span className='text-aqua'>Your Brand's Online Presence </span>
            </h2>
            <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
            Digital marketing refers to the use of digital channels, such as websites, social media, search engines, email, and mobile apps, to promote products or services. It encompasses a wide range of tactics and strategies aimed at reaching and engaging with a target audience online. Here are some key aspects of digital marketing:
            </p>
            <p className="text-gray-700 dark:text-gray-300 leading-relaxed mt-7">
                

                <li>Social Media Marketing (SMM): SMM involves using social media platforms like Facebook, Instagram, Twitter, and LinkedIn to promote your brand and engage with your audience. It includes posting regular content, running ads, and engaging with followers.

</li>
                <li>Content Marketing: Content marketing involves creating and sharing valuable, relevant content to attract and engage a target audience. This can include blog posts, videos, infographics, ebooks, and more.</li>
            </p>
        </div>
        <div className="lg:w-1/2">
            <img
                src={Digital2}
                alt="Company Image"
                className="w-full lg:h-full rounded-md"
                style={{ maxHeight: '400px' }}
            />
        </div>
    </div>
    <div className="mt-[120px]">
        <div className="lg:flex lg:justify-between">
            <div className="lg:w-1/2 lg:mr-8 mb-8 lg:mb-0">
                <img
                    src={Digital1}
                    alt="Company Image"
                    className="w-full lg:h-full rounded-md"
                    style={{ maxHeight: '400px' }}
                />
            </div>

            <div className="lg:w-1/2">

                <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                    {/* <h2 className='text-[25px]'> Security and SEO</h2> */}

                    <li>Online PR (Public Relations): Online PR involves using digital channels to manage and improve your brand's reputation online. This can include press releases, social media monitoring, and responding to customer feedback.</li>
                    <li>Mobile Marketing: Mobile marketing involves reaching and engaging with audiences on mobile devices. This can include mobile-friendly websites, mobile apps, SMS marketing, and mobile advertising.</li>
                </p>
                <p className="text-gray-700 dark:text-gray-300 leading-relaxed mt-7">
                    {/* <h2 className='text-[25px]'>Deployment and Distribution:</h2> */}

                    <li>Search Engine Marketing (SEM): SEM involves paid advertising on search engines like Google. It includes pay-per-click (PPC) ads, where advertisers pay a fee each time their ad is clicked, and other paid search tactics.</li>
                    <li>Email Marketing: Email marketing involves sending emails to a list of subscribers to promote your products or services. It can include newsletters, promotional offers, and personalized content.</li>

                </p>
            </div>
        </div>
        <Testenomail />
    </div>
</div>
  )
}

export default Digital