import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import logo4 from "../Image/tralogo.png";

const settings = {
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 4
            }
        },
        {
            breakpoint: 520,
            settings: {
                slidesToShow: 3
            }
        }
    ]
};

const Testenomail = () => {
    useEffect(() => {
        import("slick-carousel").then(() => { });
    }, []);

    return (
        <div className="lg:px-20 px-4 mx-auto mt-20">
            <p data-aos="fade-up"
          data-aos-duration="2000" className='text-blue-500 text-center text-aqua' ><h5><b>Latest News & Blogs</b></h5></p>
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white text-center">Our Partners</h2>
                
            <Slider {...settings} className="customer-logos mt-20">
                <div className="slide h-20"><img className="h-[130px]" src="https://image.freepik.com/free-vector/luxury-letter-e-logo-design_1017-8903.jpg" alt="Partner Logo" /></div>

                <div className="slide"><img className="h-[130px]" src="https://image.freepik.com/free-vector/3d-box-logo_1103-876.jpg" alt="Partner Logo" /></div>

                <div className="slide"><img className="h-[130px]" src="https://image.freepik.com/free-vector/blue-tech-logo_1103-822.jpg" alt="Partner Logo" /></div>

                <div className="slide"><img className="h-[130px]" src="https://image.freepik.com/free-vector/colors-curl-logo-template_23-2147536125.jpg" alt="Partner Logo" /></div>

                <div className="slide"><img className="h-[130px]" src="https://image.freepik.com/free-vector/abstract-cross-logo_23-2147536124.jpg" alt="Partner Logo" /></div>

                <div className="slide"><img className="h-[130px]" src={logo4} alt="Partner Logo" /></div>

                <div className="slide"><img className="h-[130px]" src="https://image.freepik.com/free-vector/background-of-spots-halftone_1035-3847.jpg" alt="Partner Logo" /></div>
            </Slider>
        </div>
    );
};

export default Testenomail;
