export  const testimonial = [
    {
        name: 'Abhimanyu Agarwal',
        role: 'CEO(FOUNDER)',
        image: 'Image/Abhimanu.jpg',
        state: 'Abhimanyu Agarwal  are the founders of Devil Infotech, establishing the company with a vision to innovate and excel in the IT industry.'
        
      },
      {
        name: 'Prakhar Tripathi',
        role: 'DIRECTOR',
        image: '/Image/prakhar.jpg',
        state: 'Prakhar Tripathi serves as the Director of Devil Infotech, overseeing the company operations and strategic direction. He plays a key role in guiding Devil Infotech.'
        
      },
      {
        name: 'Awinash sinha',
        role: 'HR',
        image: '/Image/Abhinash.png',
        state: 'It seems like you mentioned Awinash Sinha as the HR of Devil Infotech, an IT company. If you have any specific questions or need assistance related to HR.'
      },
      {
        name: 'Khant Amit',
      role: 'PROJECT MANAGER',
      image: '/Image/image2.png',
        state: 'Deepak Kumar is a valuable team member at Devil Infotech, responsible for handling various tasks within the company. His contributions play a crucial role.'

        
      },
];