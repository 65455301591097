import AOS from "aos";
import "./App.css";
import CustomerRouters from "./Components/Router/CustomerRouters.jsx";
import { useEffect } from "react";
import "aos/dist/aos.css";
import whatsapp from "./Components/Image/whatsapp.png";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    AOS.refresh();
  }, []);

  return (
    <div className="custom-scrollbar">
      <div className="App">
        {false ? "" : <CustomerRouters />}
        <div id="whatsapp">
          <a
            href="https://wa.me/9117417204"
            target="_blank"
            id="toggle1"
            class="wtsapp"
          >
            <img src={whatsapp} alt="whatsapp" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
