import React from 'react'
import app from '../Image/app.png';
import app2 from '../Image/app2.jpg';
import Testenomail from '../HomePage/Testenomail';

function Appdeve() {
    return (
        <div data-aos="fade-up" data-aos-duration="2000" className="lg:px-20 px-5 mx-auto py-8 mt-12">
            <div className='text-center'>
                <h5 data-aos="fade-up"
                    data-aos-duration="2000" className='text-blue-500 text-aqua font-bold'></h5>
                <h2 data-aos="fade-up"
                    data-aos-duration="2000" className="text-3xl lg:text-4xl font-bold text-gray-900 dark:text-white mb-4">
                    App Development
                </h2>
            </div>
            <div className="lg:flex lg:justify-between mt-20">

                <div className="lg:w-1/2 lg:mr-8 mb-8 lg:mb-0">
                    <h2 className="text-3xl lg:text-4xl font-bold text-gray-900 dark:text-white mb-4">
                        Establish your presence with <span className='text-aqua'>applications</span>
                    </h2>
                    <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                        App development, short for application development, refers to the process of creating software applications that run on mobile devices like smartphones and tablets or desktop computers. It involves several stages, from conceptualization to design, development, testing, and deployment. Here's a brief overview of key concepts in app development:
                    </p>
                    <p className="text-gray-700 dark:text-gray-300 leading-relaxed mt-7">
                        <h2 className='text-[25px]'>Platform Selection:</h2>

                        <li>App developers need to choose the right platform(s) for their target audience, such as iOS, Android, or cross-platform development frameworks like React Native.</li>
                        <li>Each platform has its own programming languages (Swift or Objective-C for iOS, Java or Kotlin for Android) and development environments.</li>
                    </p>
                </div>
                <div className="lg:w-1/2">
                    <img
                        src={app2}
                        alt="Company Image"
                        className="w-full lg:h-full rounded-md"
                        style={{ maxHeight: '400px' }}
                    />
                </div>
            </div>
            <div className="mt-[120px]">
                <div className="lg:flex lg:justify-between">
                    <div className="lg:w-1/2 lg:mr-8 mb-8 lg:mb-0">
                        <img
                            src={app}
                            alt="Company Image"
                            className="w-full lg:h-full rounded-md"
                            style={{ maxHeight: '400px' }}
                        />
                    </div>

                    <div className="lg:w-1/2">

                        <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                            <h2 className='text-[25px]'> User Interface (UI) Design:</h2>

                            <li>UI design focuses on creating visually appealing and user-friendly interfaces for apps.</li>
                            <li>It involves designing layouts, navigation elements, and interactive components to provide a seamless user experience.</li>
                        </p>
                        <p className="text-gray-700 dark:text-gray-300 leading-relaxed mt-7">
                            <h2 className='text-[25px]'>Deployment and Distribution:</h2>

                            <li>Once the app is developed and tested, it needs to be deployed to app stores like the Apple App Store or Google Play Store.</li>
                            <li>Developers need to adhere to each platform's guidelines and requirements for app submission.</li>

                        </p>
                    </div>
                </div>
                <Testenomail />
            </div>
        </div>
    )
}

export default Appdeve