
import React from 'react';
import web2 from '../Image/Wordpress.png';
import web from '../Image/Wordpress1.jpeg';
import Testenomail from '../HomePage/Testenomail';

function Wordpress() {
    return (
        <div data-aos="fade-up" data-aos-duration="2000" className="lg:px-20 px-5 mx-auto py-8 mt-12">
            <div className='text-center'>
                <h5 data-aos="fade-up"
                    data-aos-duration="2000" className='text-blue-500 text-aqua font-bold'></h5>
                <h2 data-aos="fade-up"
                    data-aos-duration="2000" className="text-3xl lg:text-4xl font-bold text-gray-900 dark:text-white mb-4">
                    WordPress Website
                </h2>
            </div>
            <div className="lg:flex lg:justify-between mt-20">

                <div className="lg:w-1/2 lg:mr-8 mb-8 lg:mb-0">
                    <h2 className="text-3xl lg:text-4xl font-bold text-gray-900 dark:text-white mb-4">
                        Creative Solutions for Your<span className='text-aqua'> WordPress Website</span>
                    </h2>
                    <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                    Devil Infotech specializes in WordPress development, offering a wide range of services to help businesses create and manage their online presence. With our expertise in WordPress, we can help you build a powerful and customized website that meets your specific needs.
                    </p>
                    <p className="text-gray-700 dark:text-gray-300 leading-relaxed mt-7">
                        {/* <h2 className='text-[25px]'>Frontend Development:</h2> */}

                        <li>Custom WordPress Development: We create custom WordPress websites tailored to your business requirements, ensuring a unique and professional online presence.</li>
                        <li>Theme Customization: Our team can customize existing WordPress themes or create a custom theme from scratch to match your branding and design preferences.</li>
                        <li>Plugin Development: We develop custom WordPress plugins to add functionality and features to your website, enhancing its performance and user experience.</li>
                    </p>
                </div>
                <div className="lg:w-1/2">
                    <img
                        src={web2}
                        alt="Company Image"
                        className="w-full lg:h-full rounded-md"
                        style={{ maxHeight: '400px' }}
                    />
                </div>
            </div>
            <div className="mt-[120px]">
                <div className="lg:flex lg:justify-between">
                    <div className="lg:w-1/2 lg:mr-8 mb-8 lg:mb-0">
                        <img
                            src={web}
                            alt="Company Image"
                            className="w-full lg:h-full rounded-md"
                            style={{ maxHeight: '400px' }}
                        />
                    </div>

                    <div className="lg:w-1/2">

                        <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                            <h2 className='text-[25px]'>Why Choose Devil Infotech?</h2>

                            <li>Expertise: Our team of WordPress developers has extensive experience in creating WordPress websites, ensuring high-quality results for every project.</li>
                            <li>Customization: We offer customized solutions to meet your specific requirements, ensuring that your website reflects your brand and meets your goals.</li>
                            <li>Client Satisfaction: We prioritize client satisfaction and work closely with you to understand your needs and deliver a website that exceeds your expectations.</li>

                        </p>
                        <p className="text-gray-700 dark:text-gray-300 leading-relaxed mt-7">
                            <h2 className='text-[25px]'>Our Services</h2>

                            <li>WordPress Website Development: We design and develop responsive WordPress websites that are visually appealing, user-friendly, and optimized for search engines.</li>
                            <li>WordPress Security: We ensure that your WordPress website is secure against threats and vulnerabilities, implementing best practices for security and protection.</li>
                        </p>
                    </div>
                </div>
                <Testenomail />
            </div>
        </div>
    )
}

export default Wordpress