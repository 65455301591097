"use client";
import React, { useState, useEffect } from "react";
// import { SubTitle } from "@/components/shared";
import { FaDiagramNext } from "react-icons/fa6";
import Marquee from "react-fast-marquee";
import { Rate } from "antd";
import { testimonial } from "../../constants/tastenomilsection";

const TestenomilSection = () => {
    const [slider, setSlider] = useState(null);
   
    return (
        <section className="mt-20">
            <div className="lg:px-20 px-4 py-8 sm:px-6 lg:me-0 lg:py-10 xl:py-15 ">
                <h5 className="mb-5 text-center text-gray-500 sm:text-xl dark:text-gray-400 font-bold text-aqua">TESTIMONIALS</h5>
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white text-center">Check What Our Clients
                    Say About Us</h2>
                <Marquee>
                    <div className="flex mt-12 min-w-screen-2xl justify-between items-end gap-6 lg:gap-6 px-3  ">
                        {testimonial.map((item) => {
                            return (
                                <>
                                    <div
                                        className="bg-gray-50 p-6 shadow-sm sm:p-8 w-[300px] lg:w-[400px]"
                                        key={item.id}
                                    >
                                        <div className="flex items-center gap-4">
                                            <img
                                                alt={item.name}
                                                src={item.image}
                                                className="size-14 rounded-full object-cover"
                                            />
                                            <div>
                                                {/* <div className="flex justify-center gap-0.5 text-green-500">
                                                    <Rate disabled defaultValue={5} />
                                                </div> */}
                                                <p className="mt-0.5 text-xl font-medium text-gray-900">
                                                    {item.name}
                                                    
                                                </p>
                                            </div>
                                    
                                        </div>
                                        <p className="mt-4 text-black text-lg font-normal">
                                           {item.state}
                                        </p>
                                    </div>{" "}
                                </>
                            );
                        })}
                    </div>
                </Marquee>
            </div>
        </section>
    );
};

export default TestenomilSection;