import React from 'react'
import { testimonial } from '../../constants/technology';

function Technology() {

    return (
        <div className=' mt-20'>
            <div data-aos="fade-up"
                data-aos-duration="2000" className=" px-5 xl:px-20 flex flex-wrap">
                <div data-aos="fade-up"
                    data-aos-duration="2000" className="w-full px-4">
                    <div data-aos="fade-up"
                        data-aos-duration="2000" className="mx-auto mb-12 max-w-[510px] text-center lg:mb-20">

                        <h5 data-aos="fade-up"
                            data-aos-duration="2000" className="mb-5 text-center text-gray-500 sm:text-xl dark:text-gray-400 font-bold text-aqua">TECHNOLOGIES WE USE</h5>
                        <h2 data-aos="fade-up"
                            data-aos-duration="2000" className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white text-center">Latest tools & Technologies</h2>
                    </div>
                </div>
                <div data-aos="fade-up"
                    data-aos-duration="2000" className='grid grid-cols-1 w-full h-full xl:grid-cols-6 sm:grid-cols-3 gap-5'>
                    {testimonial.map((item, i) => {
                        return (

                            <img data-aos="fade-up"
                                data-aos-duration="2000"
                                key={i}
                                className='lg:h-[170px] sm:h-[170px] w-full shadow-md shadow-black p-10 cursor-pointer '
                                alt={item.name}
                                src={item.image}
                            />

                        );
                    })}
                </div>
            </div>
        </div>
    )
}

export default Technology