export const posts = [
    {
        id: 1,
        title: 'IT COMPANY WEBSITE DEVELOPMENT',
        content: 'In today digital age, a website is often the first point of contact between a business and its potential customers. For an IT company, a well-designed website is not just a digital brochure but a showcase of expertise, innovation, and professionalism. Here, we delve into the art and science behind developing an impactful website for an IT company.',
        author: 'Abhimanyu',
        date: 'April 5, 2024',
        image: '/Image/Blog-3.webp',
    },
    {
        id: 2,
        title: 'HOW TO BUILD AN ON-DEMAND SERVICE MOBILE APP',
        content: 'The features of your on-demand service app will depend on the nature of your service. However, some common features include user registration and login, service provider profiles, real-time tracking, in-app messaging, secure payment options, and ratings/reviews.',
        author: 'Abhimanyu',
        date: 'April 5, 2024',
        image: '/Image/Blog-2.jpg',
    },
    {
        id: 3,
        title: 'HOW TO DESIGN IN WEBSITE',
        content: 'Designing a website is a complex process that requires careful planning, creativity, and attention to detail. By following these steps and staying focused on your goals, you can create a visually appealing and user-friendly website that effectively communicates your message and engages your audience.',
        author: 'Abhimanyu',
        date: 'April 5, 2024',
        image: '/Image/Blog-1.jpg',
    },
];