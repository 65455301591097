import  { useState } from 'react'
import InternshipDet from './InternshipDet'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';


function Internship() {
    const form = useRef();
    const [formData, setFormData] = useState({
        fname: '',
        sname: '',
        lname: '',
        college: '',
        university: '',
        course: '',
        department: '',
        semester: '',
        enrollment: '',
        technology: '',
        contact: '',
        email: '',
        address: ''
    });

    console.log("formdata", formData)

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("name", name)
        setFormData({
            ...formData,
            [name]: value
        });
        console.log("formdata", formData)
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Basic form validation
        const requiredFields = ['fname', 'sname', 'lname', 'college', 'university', 'course', 'department', 'semester', 'enrollment', 'technology', 'contact', 'email', 'address'];
        const missingFields = requiredFields.filter(field => !formData[field]);
    
        if (missingFields.length > 0) {
            toast.error('Please fill out all required fields.');
            return;
        }
    
        emailjs.sendForm('service_7ika736', 'template_2ctb1hs', form.current, {
            publicKey: '_26M9UquTQuAjqJJV',
        })
        .then(
            () => {
                console.log('SUCCESS!');
                toast.success('Submit Successfully');
            },
            (error) => {
                console.log('FAILED...', error.text);
                toast.error('Something went wrong');
            }
        );
    
        e.target.reset();
    };
    
    return (
        <div>
            <div className='px-5 mt-20 mb-20'>
                <div className="mx-auto mb-12 max-w-[510px] text-center lg:mb-20">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white text-center">Internship At DevilInfotech</h2>
                </div>
                <InternshipDet />
                <form ref={form} className="mx-auto lg:px-[130px] mt-12" onSubmit={handleSubmit}>
                    <div><h2 className='font-bold'>Apply Here</h2></div>
                    <div className="flex flex-wrap -mx-3 mb-6 mt-8">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block text-base tracking-wide text-gray-700  mb-2"
                                htmlFor="grid-first-name"
                            >
                                First Name<span className='text-red'>*</span>
                            </label>
                            <input
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"

                                type="text"
                                required
                                name="fname"
                                value={formData?.fname}
                                onChange={handleChange}

                                placeholder="First Name"
                            />
                        </div>
                        <div className="w-full md:w-1/3 px-3">
                            <label
                                className="block text-base tracking-wide text-gray-700  mb-2"
                                htmlFor="grid-last-name"
                            >
                                Second Name<span className='text-red'>*</span>
                            </label>
                            <input
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-last-name"
                                type="text"
                                name="sname"
                                onChange={handleChange}
                                value={formData?.sname}
                                placeholder="Second Name"
                            />
                        </div>
                        <div className="w-full md:w-1/3 px-3">
                            <label
                                className="block text-base tracking-wide text-gray-700  mb-2"
                                htmlFor="grid-last-name"
                            >
                                Last Name<span className='text-red'>*</span>
                            </label>
                            <input
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-last-name"
                                type="text"
                                required
                                name="lname"
                                onChange={handleChange}
                                value={formData.lname}
                                placeholder="Last Name"
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block tracking-wide text-gray-700 text-base mb-2"
                                htmlFor="grid-first-name"
                            >
                                College Name<span className='text-red'>*</span>
                            </label>
                            <input
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                id="grid-first-name"
                                type="text"
                                required
                                name='college'
                                onChange={handleChange}
                                value={formData.college}
                                placeholder="College Name"
                            />
                        </div>
                        <div className="w-full md:w-1/3 px-3">
                            <label
                                className="block text-base tracking-wide text-gray-700  mb-2"
                                htmlFor="grid-last-name"
                            >
                                University Name<span className='text-red'>*</span>
                            </label>
                            <input
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-last-name"
                                type="text"
                                required
                                name='university'
                                onChange={handleChange}
                                value={formData.university}
                                placeholder="University Name"
                            />
                        </div>
                        <div className="w-full md:w-1/3 px-3">
                            <label
                                className="block text-base tracking-wide text-gray-700  mb-2"
                                htmlFor="grid-last-name"
                            >
                                Course<span className='text-red'>*</span>
                            </label>
                            <div className="relative">
                                <select required
                                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="grid-state"
                                    name='course'
                                    onChange={handleChange}
                                    value={formData.course}
                                >
                                    <option value={"select"}>Select</option>
                                    <option value={"bsc"}>Bsc It</option>
                                    <option>Msc It</option>
                                    <option>BCA</option>
                                    <option>MCA</option>
                                    <option>B.E.</option>
                                    <option>M.E.</option>
                                    <option>B.Tech.</option>
                                    <option>M.Tech.</option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                        className="fill-current h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block  tracking-wide text-gray-700 text-base mb-2"
                                htmlFor="grid-first-name"
                            >
                                Department<span className='text-red'>*</span>
                            </label>
                            <input
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                id="grid-first-name"
                                type="text"
                                required
                                name='department'
                                onChange={handleChange}
                                value={formData.department}
                                placeholder="Department"
                            />
                        </div>
                        <div className="w-full md:w-1/3 px-3">
                            <label
                                className="block  tracking-wide text-gray-700 text-base mb-2"
                                htmlFor="grid-last-name"
                            >
                                Semester<span className='text-red'>*</span>
                            </label>
                            <div className="relative">
                                <select required
                                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="grid-state"
                                    name='semester'
                                    onChange={handleChange}
                                    value={formData.semester}
                                >
                                    <option>Select</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                        className="fill-current h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </div>
                            </div>

                        </div>
                        <div className="w-full md:w-1/3 px-3">
                            <label
                                className="block  tracking-wide text-gray-700 text-base	  mb-2"
                                htmlFor="grid-last-name"
                            >
                                Enrollment Number<span className='text-red'>*</span>
                            </label>
                            <input
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-last-name"
                                type="text"
                                required
                                name='enrollment'
                                onChange={handleChange}
                                value={formData.enrollment}
                                placeholder="Enrollment Number"
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block  tracking-wide text-gray-700 text-base mb-2"
                                htmlFor="grid-first-name"
                            >
                                Technology<span className='text-red'>*</span>
                            </label>
                            <div className="relative">
                                <select required
                                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    id="grid-state"
                                    name='technology'
                                    onChange={handleChange}
                                    value={formData.technology}
                                >
                                    <option>Select</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg
                                        className="fill-current h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/3 px-3">
                            <label
                                className="block  tracking-wide text-gray-700 text-base mb-2"
                                htmlFor="grid-last-name"
                            >
                                Contect Number<span className='text-red'>*</span>
                            </label>
                            <input
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-last-name"
                                type="text"
                                required
                                name='contact'
                                onChange={handleChange}
                                value={formData.contact}
                                placeholder="Contect Number"
                            />

                        </div>
                        <div className="w-full md:w-1/3 px-3">
                            <label
                                className="block  tracking-wide text-gray-700 text-base	  mb-2"
                                htmlFor="grid-last-name"
                            >
                                Email<span className='text-red'>*</span>
                            </label>
                            <input
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-last-name"
                                type="text"
                                required
                                name='email'
                                onChange={handleChange}
                                value={formData.email}
                                placeholder="Email"
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label
                                className="block text-base tracking-wide text-gray-700  mb-2"
                                htmlFor="grid-password"
                            >
                                Address<span className='text-red'>*</span>
                            </label>
                            <input
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-password"
                                type="text"
                                required
                                name='address'
                                onChange={handleChange}
                                value={formData.address}
                                placeholder="Address"
                            />

                        </div>
                    </div>
                    <div className=''>
                        <button type='submit' className='bg-aqua w-[100px] hover:bg-black hover:text-white transition delay-75 h-10 rounded-lg'>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Internship
