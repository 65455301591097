export const teamMembers = [
    {
      name: 'Abhimanyu Agarwal',
      role: 'CEO(FOUNDER)',
      image: 'Image/Abhimanu.jpg',
      Linkdin: 'https://www.linkedin.com/in/abhimanyu-agrawal-57b35326b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
      instagram: 'https://www.instagram.com/',
      facebook: 'https://www.facebook.com/',
    },
    {
      name: 'Prakhar Tripathi',
      role: 'DIRECTOR',
      image: '/Image/prakhar.jpg',
      Linkdin: 'https://www.linkedin.com/in/prakhar-a-tripathi?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
      instagram: 'https://www.instagram.com/prakhar.175?igsh=YnU2bmQzdjloZXhq',
      facebook: 'https://www.facebook.com/',
    },
    {
      name: 'Awinash sinha',
      role: 'HR',
      image: '/Image/Abhinash.png',
      youtube: 'https://www.youtube.com/',
      instagram: 'https://www.instagram.com/awinash49?igsh=MXV5bTBnNm92eDJq',
      facebook: 'https://www.facebook.com/',
    },
    {
      name: 'Khant Amit',
      role: 'PROJECT MANAGER',
      image: '/Image/image2.png',
      Linkdin: 'https://www.youtube.com/',
      instagram: 'https://www.instagram.com/',
      facebook: 'https://www.facebook.com/',
    },
  ];