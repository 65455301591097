import React from 'react';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Link } from 'react-router-dom';
import AboutImage from '../Image/About-image.avif'

const AboutHome = () => {
  return (
    <div data-aos="fade-up" data-aos-duration="2000" className="">
      <div className="lg:px-20 px-4 mx-auto py-8 mt-12">
        <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-between">
          <div className="lg:w-1/2 lg:mr-8 mb-8 lg:mb-0">
            <h5 data-aos="fade-up"
              data-aos-duration="2000" className='text-blue-500 text-aqua font-bold'>ABOUT US</h5>
            <h2 data-aos="fade-up"
     data-aos-duration="2000" className="text-3xl lg:text-4xl font-bold text-gray-900 dark:text-white mb-4">
              Welcome To Devil Infotech
            </h2>
            <p data-aos="fade-up"
     data-aos-duration="2000" className="text-gray-700 dark:text-gray-300 leading-relaxed">
              Devil Infotech is a leading IT service company dedicated to providing cutting-edge solutions in web development, app development, and a wide range of IT services. Established with a vision to deliver innovative and reliable solutions, we strive to exceed client expectations and set new benchmarks in the industry.
            </p>
            <p data-aos="fade-up"
     data-aos-duration="2000" className="text-gray-700 dark:text-gray-300 leading-relaxed mt-7">
              Our mission is to empower businesses with technology-driven solutions that enhance efficiency, productivity, and growth. We aim to be a trusted partner for our clients, offering tailored services that align with their unique goals and objectives.

            </p>
            {/* <div className='p-5'>
              <LocalPhoneIcon />
            </div> */}
            <Link to={"/about"}><button
              color="teal"
              size="lg"
              ripple="light"
              className="hover:bg-black hover:text-white transition duration-300 bg-aqua p-3 mt-3"
            >
              Read More
            </button>
            </Link>
          </div>
          <div data-aos="fade-up"
            data-aos-duration="3000" className="lg:w-1/3">
            <img
              src={AboutImage}
              alt="Company Image"
              className=" md:w-[400px] "
              style={{ height: '400px', width: '600px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHome;
