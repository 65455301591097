import React from 'react';
import { Card, CardContent, CardMedia, Grid, IconButton, Tooltip } from '@mui/material';
import { FaYoutube, FaInstagram, FaFacebook, FaLinkedin } from 'react-icons/fa';
import { teamMembers } from '../../constants/teamsection';




const TeamSection = () => {
  return (
    <div data-aos="fade-up"
      data-aos-duration="2000" className='' >
      <div data-aos="fade-up"
        data-aos-duration="2000" className=" mx-auto lg:px-20 px-4 py-16 mt-20">
        <p data-aos="fade-up"
          data-aos-duration="2000" className='text-blue-500 text-center text-aqua' ><h5><b>OUR EXPERT </b></h5></p>
        <h2 data-aos="fade-up"
          data-aos-duration="2000" className="mb-3 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">Brain Behind Devil Infotech</h2>
        <Grid data-aos="fade-up"
          data-aos-duration="2000" container spacing={4} className='mt-20'>
          {teamMembers.map((member, index) => (
            <Grid item key={index} xs={12} sm={6} md={3}>
              <Card className="relative group mt-12 cursor-pointer">
                <CardMedia data-aos="zoom-in" data-aos-duration="2000"
                  component="img"
                  height="150"
                  image={member.image}
                  alt={member.name}
                  className="transition-all duration-300 ease-in-out group-hover:scale-105 h-[330px]"
                />
                {/* bg-white */}
                <CardContent className="absolute bottom-0 left-0 w-full bg-white  bg-opacity-80 backdrop-blur-md transition-all duration-300 ease-in-out opacity-0 group-hover:opacity-100">
                  <h3 className="text-lg text-center font-medium text-black">{member.name}</h3>
                  <p className="text-sm text-center">{member.role}</p>
                  <div className="flex justify-center space-x-2 mt-2 ">
                    <Tooltip title="Linkdin">
                      <IconButton href={member.Linkdin} target="_blank" rel="noopener noreferrer">
                        <FaLinkedin className='text-black' />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Instagram">
                      <IconButton href={member.instagram} target="_blank" rel="noopener noreferrer">
                        <FaInstagram className='text-black' />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Facebook">
                      <IconButton href={member.facebook} target="_blank" rel="noopener noreferrer">
                        <FaFacebook className='text-black' />
                      </IconButton>
                    </Tooltip>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default TeamSection;
