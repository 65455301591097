
    import React from 'react';
    import web2 from '../Image/webdesig1.jpg';
    import web from '../Image/webdesig.jpg';
    import Testenomail from '../HomePage/Testenomail';
    
    function Webdesign() {
        return (
            <div data-aos="fade-up" data-aos-duration="2000" className="lg:px-20 px-5 mx-auto py-8 mt-12">
                <div className='text-center'>
                    <h5 data-aos="fade-up"
                        data-aos-duration="2000" className='text-blue-500 text-aqua font-bold'></h5>
                    <h2 data-aos="fade-up"
                        data-aos-duration="2000" className="text-3xl lg:text-4xl font-bold text-gray-900 dark:text-white mb-4">
                        Website Design
                    </h2>
                </div>
                <div className="lg:flex lg:justify-between mt-20">
    
                    <div className="lg:w-1/2 lg:mr-8 mb-8 lg:mb-0">
                        <h2 className="text-3xl lg:text-4xl font-bold text-gray-900 dark:text-white mb-4">
                            Creative Solutions for Your<span className='text-aqua'> Website Design</span>
                        </h2>
                        <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                        Devil Infotech offers comprehensive web design services tailored to meet the unique needs of each client. Our team of skilled designers combines creativity with technical expertise to deliver stunning websites that engage users and drive results.
                        </p>
                        <p className="text-gray-700 dark:text-gray-300 leading-relaxed mt-7">
                            {/* <h2 className='text-[25px]'>Frontend Development:</h2> */}
    
                            <li>Understanding Your Business: We begin by understanding your business goals, target audience, and competition to create a design strategy that aligns with your objectives.</li>
                            <li>Custom Design: Our designers create custom website designs that reflect your brand identity and provide a seamless user experience across all devices.</li>
                            <li>Responsive Design: We ensure that your website is responsive, adapting to different screen sizes and devices to provide a consistent experience for all users.</li>
                        </p>
                    </div>
                    <div className="lg:w-1/2">
                        <img
                            src={web2}
                            alt="Company Image"
                            className="w-full lg:h-full rounded-md"
                            style={{ maxHeight: '400px' }}
                        />
                    </div>
                </div>
                <div className="mt-[120px]">
                    <div className="lg:flex lg:justify-between">
                        <div className="lg:w-1/2 lg:mr-8 mb-8 lg:mb-0">
                            <img
                                src={web}
                                alt="Company Image"
                                className="w-full lg:h-full rounded-md"
                                style={{ maxHeight: '400px' }}
                            />
                        </div>
    
                        <div className="lg:w-1/2">
    
                            <p className="text-gray-700 dark:text-gray-300 leading-relaxed">
                                <h2 className='text-[25px]'>Why Choose Devil Infotech?</h2>
    
                                <li>Experienced Team: Our team has years of experience in web design and development, ensuring high-quality results for every project.</li>
                                <li>Customized Solutions: We tailor our web design services to meet your specific requirements and objectives, ensuring that you get a website that meets your needs.</li>
                                <li>Client Satisfaction: We prioritize client satisfaction and work closely with you throughout the design process to ensure that your vision is realized.</li>
    
                            </p>
                            <p className="text-gray-700 dark:text-gray-300 leading-relaxed mt-7">
                                <h2 className='text-[25px]'>Our Services</h2>
    
                                <li>Website Redesign: If your current website needs a refresh, our team can redesign it to improve its appearance, functionality, and user experience.</li>
                                <li>UI/UX Design: Our designers pay attention to every detail of the user interface and user experience to create a seamless and enjoyable interaction for your website visitors.</li>
                            </p>
                        </div>
                    </div>
                    <Testenomail />
                </div>
            </div>
        )
    }
    
    export default Webdesign