import React from 'react'
import AboutHome from '../HomePage/AboutHome'
import TeamSection from '../HomePage/TeamSection'
import Testenomail from '../HomePage/Testenomail'
import ServiceSection from '../HomePage/ServiceSection'
import ContupSection from '../HomePage/ContupSection'
import Popup from './Popup'

function About() {
  return (
    <div>
    <AboutHome />
    <ServiceSection/>
    <ContupSection />
    <TeamSection />
    <Popup/>
    <Testenomail/>
    </div>
  )
}

export default About