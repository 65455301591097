import React, { useState } from 'react';

const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li className="bg-white my-2 shadow-lg">
      <h2
        onClick={toggleAccordion}
        className="flex justify-between items-center font-semibold p-3 cursor-pointer"
      >
        <span>{question}</span>
        <svg
          className={`fill-current text-purple-700 h-6 w-6 transform transition-transform duration-500 ${isOpen ? 'rotate-180' : ''}`}
          viewBox="0 0 20 20"
        >
          <path d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
        </svg>
      </h2>
      <div
        className={`border-l-2 border-purple-600 overflow-hidden duration-1000 ${isOpen ? `max-h-[200px] opacity-100 ` : `max-h-0 opacity-0`}`}
      >
        <p className="p-3 text-gray-900">{answer}</p>
      </div>
    </li>
  );
};

const Faq = () => {
  const faqItems = [
    {
      question: 'What is the purpose of an IT company website?',
      answer: 'The purpose of an IT company website is to showcase the companys services, expertise, and portfolio, attract potential clients, and provide information about the company and its team.'
    },
    {
      question: 'What are some best practices for content creation on an IT company website?',
      answer: 'Some best practices for content creation on an IT company website include creating original and informative content, using visuals such as images and videos to enhance the content, and optimizing content for search engines.'
    },
    {
      question: 'What technologies are commonly used to develop an IT company website?',
      answer: 'Common technologies used to develop an IT company website include HTML, CSS, JavaScript for front-end development, and server-side languages like PHP, Python, or Node.js for back-end development. Content management systems like WordPress or Drupal are also popular choices.'
    },
    {
      question: 'Why hire developers from an agency instead of freelancers?',
      answer: 'Reliability and Accountability: Agencies typically have a reputation to uphold and are more likely to deliver on their promises. They are accountable for the work of their developers and can provide replacements if needed.'
    },
    {
      question: 'What are the main challenges in app development?',
      answer: 'Platform Fragmentation: Developing for multiple platforms (e.g., Android and iOS) can be challenging due to differences in coding languages, design guidelines, and device capabilities.'
    },

  ];

  return (
    <main data-aos="fade-up"
      data-aos-duration="2000" className="lg:px-20 px-4 mx-auto py-8 mt-12 w-full">
      <div data-aos="fade-up"
        data-aos-duration="2000" className="flex justify-center items-start my-2">
        <div data-aos="fade-up"
          data-aos-duration="2000" className="w-full ">
          <h5 className="mb-5 text-center text-gray-500 sm:text-xl dark:text-gray-400 font-bold text-aqua">OUR ANSWERS</h5>
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white text-center">Frequently Asked Questions</h2>
          <ul data-aos="fade-up"
            data-aos-duration="2000" className="flex flex-col mt-12">
            {faqItems.map((item, index) => (
              <FaqItem key={index} question={item.question} answer={item.answer} />
            ))}
          </ul>
        </div>
      </div>
    </main>
  );
};

export default Faq;
